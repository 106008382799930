import React from "react";
import { useCountUp } from "react-countup";

const SetionReport = () => {
  const countUpNewVisitor = React.useRef(null);
  const countUpNewVisitorPercent = React.useRef(null);
  const countUpAdoptionDigitalProduct = React.useRef(null);
  const countUpAdoptionDigitalProductPercent = React.useRef(null);

  useCountUp({
    ref: countUpNewVisitor,
    end: 463,
    enableScrollSpy: true,
    scrollSpyOnce: true,
  });
  useCountUp({
    ref: countUpNewVisitorPercent,
    end: 41,
    enableScrollSpy: true,
    suffix: "%",
    scrollSpyOnce: true,
  });
  useCountUp({
    ref: countUpAdoptionDigitalProduct,
    end: 108,
    enableScrollSpy: true,
    scrollSpyOnce: true,
  });
  useCountUp({
    ref: countUpAdoptionDigitalProductPercent,
    end: 18,
    enableScrollSpy: true,
    suffix: "%",
    scrollSpyOnce: true,
  });

  return (
    <section className="landing-report">
      <div className="landing-container">
        <ul className="landing-report__list">
          <li>
            <span className="landing-tag">Website new visitor</span>
            <span className="landing-report__list__amount">
              <img
                src="/annual-report-2023/statistic-avatar-user.png"
                loading="lazy"
                alt="visitor"
                height="46px"
                width="46px"
              />
              <strong ref={countUpNewVisitor} />
              <span className="landing-report__list__percent">
                <img
                  src="/annual-report-2023/statistic-icon-grow.svg"
                  loading="lazy"
                  alt="visitor grow up"
                  height="32px"
                  width="61px"
                />
                <strong ref={countUpNewVisitorPercent} />
              </span>
            </span>
            <span className="landing-report__list__desc">
              Total pengguna baru yang mengakses website Legion. Naik{" "}
              <strong>41%</strong> dibanding tahun 2022.
            </span>
          </li>
          <li>
            <span className="landing-tag">Adoption in Digital Product</span>
            <span className="landing-report__list__amount">
              <img
                src="/annual-report-2023/statistic-avatar-logo-legion.svg"
                loading="lazy"
                alt="adoption"
                height="46px"
                width="46px"
              />
              <strong ref={countUpAdoptionDigitalProduct} />
              <span className="landing-report__list__percent">
                <img
                  src="/annual-report-2023/statistic-icon-grow.svg"
                  loading="lazy"
                  alt="adoption grow up"
                  height="32px"
                  width="61px"
                />
                <strong ref={countUpAdoptionDigitalProductPercent} />
              </span>
            </span>
            <span className="landing-report__list__desc">
              Produk digital (DTP) di Telkom Indonesia yang menggunakan Legion.
              Naik <strong>18%</strong> dari tahun 2022 dan akan terus
              bertambah!
            </span>
          </li>
        </ul>
      </div>
      <div className="landing-report__decoration"></div>
    </section>
  );
};

export default SetionReport;
